<template>
	<div class="it-index">
		<edit-toolbar v-if="isDraft" :editor="editor"></edit-toolbar>

		<div
			class="it-index-panel"
			:style="{
				left: 0,
				width: (leftPanelSelected > -1 ? 480 : 80) + 'px',
				top: isDraft ? '50px' : 0
			}"
		>
			<left-panel
				:leftPanelsTop="leftPanelsTop"
				:leftPanelsBottom="leftPanelsBottom"
				:leftPanelSelected.sync="leftPanelSelected"
			/>
		</div>

		<main
			:id="styleTargetId"
			class="it-index-main"
			:style="{
				left: (leftPanelSelected > -1 ? 480 : 80) + 'px',
				top: isDraft ? '50px' : 0
			}"
		>
			<interactive-text-canvas :settingStyles="settingStyles" class="p-block-designer-canvas" :editor="editor">
			</interactive-text-canvas>
		</main>
	</div>
</template>

<script>
import LeftPanel from '../../lessonPlan/components/structure/LeftPanel.vue';
import InteractiveTextCanvas from './InteractiveTextCanvas.vue';
import EditToolbar from './structure/EditToolbar';
import _ from 'lodash';

import { Editor } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';
import FontFamily from '@tiptap/extension-font-family';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import Underline from '@tiptap/extension-underline';
import Heading from '@tiptap/extension-heading';
import TextAlign from '@tiptap/extension-text-align';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import { mergeAttributes } from '@tiptap/core';
import uuid from '../../../utils/uuid';
import { Fields } from '../../../components/lessonPlan/blocks/_components/extensions/tiptap/Fields';
import { OrderListClass, Clipboard, FormulaMark, FormulaClass, FontSize } from 'plant-common';
import PlantLibraryComponent from './extensions/plantLibraryExtension.js';
import InteractiveText from './extensions/interactiveTextExtension.js';
import PServiceContent from 'plant-common/src/services/PServiceContent';
import { mapActions } from 'vuex';
import { ACTION_LOCK_DRAFT, STORE_MODULE_EDITOR } from '../../../store_constants/content';

export default {
	name: 'InteractiveTextDesigner',
	inject: ['controller'],
	components: {
		LeftPanel,
		InteractiveTextCanvas,
		EditToolbar
	},
	props: {
		settingStyles: Object,
		leftPanelsTop: Array,
		leftPanelsBottom: Array
	},
	data() {
		return {
			leftPanelSelected: -1,
			editor: null,
			editorContent: null
		};
	},
	computed: {
		isDraft() {
			return this.controller.isDraft;
		},
		styleTargetId() {
			return this.controller.styleTargetId;
		}
	},
	async created() {
		await this.getPageContent();
		this.initEditor();
		this.controller.editor = this.editor;
	},
	beforeDestroy() {
		if (this.editor) this.editor.destroy();
	},
	methods: {
		async getPageContent() {
			this.editorContent = '';

			if (this.controller.page.html && this.controller.page.html.url) {
				const res = await fetch(this.controller.page.html.url);
				this.editorContent = res.ok ? await res.text() : '';
			}
		},
		...mapActions(STORE_MODULE_EDITOR, {
			lockDraft: ACTION_LOCK_DRAFT
		}),
		initEditor() {
			if (this.editor) {
				this.editor.destroy();
			}

			const styleEditor =
				'min-height: ' +
				(this.minHeight ? this.minHeight : '500') +
				'px; padding: 15px; outline: none; font-size: ' +
				(this.fontSize ? this.fontSize : '14') +
				'px';

			const saveDebounce = _.debounce(async (triggerPage, contentHTML, libraryContents) => {
				this.editorContent = this.editor.getHTML();
				await this.controller.saveTextAsFile(triggerPage, contentHTML);
				this.removeUnusedLibraryContents(triggerPage, libraryContents);
				this.removeUnusedObjectives();
				this.lockDraft(false);
			}, 1000);

			this.editor = new Editor({
				editable: this.isDraft,
				autofocus: true,
				onUpdate: () => {
					const libraryContents = this.editor.options.element.querySelectorAll(
						'.ProseMirror .plant-library-component__wrapper'
					);
					const itContents = this.editor.options.element.querySelectorAll('.ProseMirror .it-component__wrapper');
					const contents = [...libraryContents, ...itContents];

					this.lockDraft(true);
					saveDebounce(this.controller.page, this.editor.getHTML(), contents);
				},
				editorProps: {
					attributes: {
						style: styleEditor
					}
				},
				extensions: [
					StarterKit.configure({
						heading: false
					}),
					Heading.configure({ levels: [1, 2, 3] }).extend({
						levels: [1, 2, 3],
						renderHTML({ node, HTMLAttributes }) {
							const hasLevel = this.options.levels.includes(node.attrs.level);
							const level = hasLevel ? node.attrs.level : this.options.levels[0];
							return [`h${level}`, mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { id: uuid() }), 0];
						}
					}),
					FontSize,
					TextStyle,
					TextAlign.configure({
						types: ['heading', 'paragraph']
					}),
					Table.configure({
						resizable: true,
					}),
					TableRow,
					TableHeader,
					TableCell,
					Color,
					FontFamily,
					Subscript,
					Superscript,
					Underline,
					Fields,
					OrderListClass,
					Clipboard,
					PlantLibraryComponent,
					InteractiveText,
					FormulaMark,
					FormulaClass
				],
				content: this.editorContent
			});
		},
		removeUnusedLibraryContents(triggerPage, libraryContents) {
			if (!libraryContents.length) {
				triggerPage.htmlDependencies = [];
				return;
			}

			let contentOrigins = [];
			libraryContents.forEach(content => contentOrigins.push(content.attributes.origin.value));
			contentOrigins = [...new Set(contentOrigins)]; // Remove duplicates

			triggerPage.htmlDependencies = triggerPage.htmlDependencies.filter(content =>
				contentOrigins.includes(content.$origin)
			);
			contentOrigins.forEach(async origin => {
				const index = triggerPage.htmlDependencies.findIndex(dependency => dependency.$origin === origin);
				if (index === -1) {
					let dep = await PServiceContent.getContentFromRef(origin);
					dep.$origin = origin;
					triggerPage.htmlDependencies.push(dep);
				}
			});
		},
		removeUnusedObjectives() {
			if (this.controller.ibObjectives) {
				this.controller.ibObjectives.forEach((o, index) => {
					if (
						this.controller.objectivesFromItsFromPage.findIndex(
							obj => obj.header.metadata.content_id === o.header.metadata.content_id
						) === -1
					) {
						this.controller.ibObjectives.splice(index, 1);
					}
				});
			}
		}
	}
};
</script>

<style lang="scss">
.it-page {
	table {
		border-collapse: collapse;
		margin: 0;
		overflow: hidden;
		table-layout: fixed;
		width: 100%;
		margin: 1.5rem 0;
		overflow-x: auto;

		td,
		th {
			border: 1px solid grey;
			box-sizing: border-box;
			min-width: 1em;
			padding: 6px 8px;
			position: relative;
			vertical-align: top;

			> * {
				margin-bottom: 0;
			}
		}

		th {
			background-color: lightgrey;
			font-weight: bold;
			text-align: left;
		}

		.selectedCell:after {
			background: rgba(212, 212, 212, 0.44);
			content: "";
			left: 0; right: 0; top: 0; bottom: 0;
			pointer-events: none;
			position: absolute;
			z-index: 2;
		}

		.column-resize-handle {
			background-color: darkslategrey;
			bottom: -2px;
			pointer-events: none;
			position: absolute;
			right: -2px;
			top: 0;
			width: 4px;
		}
	}

	.resize-cursor {
		cursor: ew-resize;
		cursor: col-resize;
	}
}

</style>